<template>
  <b-container fluid class="reports-performance-savings-ratio-percentage-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'savings-ratio-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>

  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'

export default {
  name: 'ReportPercentageChart',
  props: ['apiData'],
  mixins: [chartOptions],
  components: {
    LineChart
  },
  data () {
    return {
      translationPath: 'sandbox.report_template.percentage.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  methods: {
    fillChart () {
      const dataset01 = {
        label: this.$t(this.translationPath + 'dataset01'),
        data: [10, 15, 15, 17, 25],
        backgroundColor: '#FEC600'
      }

      this.chartData = {
        labels: [2015, 2016, 2017, 2018, 2019],
        datasets: [dataset01]
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-savings-ratio-percentage-chart{

  }
</style>

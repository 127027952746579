<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="percentageTab" :class="{selected: activeTab === 'percentageTab'}"  @click="onTabClick">
          {{ $t(translationPath + 'percentage') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="amountTab" :class="{selected: activeTab === 'amountTab'}"  @click="onTabClick">
          {{ $t( translationPath + 'amount') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'percentageTab'
    }
  },
  name: 'SavingsSubmenu',
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <b-container fluid class="reports-savings-ratio-percentage-data-content-wrapper">

    <b-row class="" no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card depth-1 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'title')}} </h2>
          </template>

          <b-col>
            content
          </b-col>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  name: 'ReportPercentageDataContent',
  props: ['apiData'],
  components: {},
  data () {
    return {
      translationPath: 'sandbox.report_template.percentage.'
    }
  }
}
</script>

<style lang="scss">
  .reports-savings-ratio-percentage-data-content-wrapper{

  }
</style>
